import React, { useState } from "react";
import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";

import { get, post } from "../api";

interface PasswordResetProps {
  isSignUp: boolean;
}

const PasswordReset: React.FC<PasswordResetProps> = ({ isSignUp }) => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const uid = searchParams.get("uid");
  const token = searchParams.get("token");

  const [fieldValue, setFieldValue] = useState("");
  const [responseMessage, setResponseMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const handleTextfieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.value);
  };

  const handleSubmit = async () => {
    if (uid && token) {
      setIsLoading(true);
      const { result, status } = await post(
        "/reset-password",
        { uid, token, password: fieldValue },
        logout
      );

      navigate("/login");
    } else {
      const pattern: RegExp = /^[\w\.\-+]+@[\w\.-]+\.\w+$/;
      if (!pattern.test(fieldValue)) {
        setErrorMessage("Please enter a valid email address.");
        return;
      } else {
        setIsLoading(true);
        const { result, status } = await get("/reset-password", logout, {
          email: fieldValue,
        });
        setErrorMessage("");
        setResponseMessage(result.message);
      }
    }
    setIsLoading(false);
  };

  return (
    <Container maxWidth="xs">
      <div>
        <h2>{isSignUp ? "Enter your email" : "Reset password"}</h2>
        <form>
          <Grid container spacing={2}>
            {uid && token ? (
              <Grid item xs={12}>
                <TextField
                  label="Password"
                  type="password"
                  variant="outlined"
                  fullWidth
                  value={fieldValue}
                  onChange={handleTextfieldChange}
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  value={fieldValue}
                  onChange={handleTextfieldChange}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              {errorMessage && (
                <Typography variant="body2" color="error">
                  {errorMessage}
                </Typography>
              )}
              {responseMessage && (
                <Typography variant="body2" color="success">
                  {responseMessage}
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {isLoading && <CircularProgress />}
              {!isLoading && !responseMessage && (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={handleSubmit}
                >
                  {uid && token ? "Set Password" : "Submit"}
                </Button>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default PasswordReset;
