import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { TabPanel } from "@mui/lab";
import { Skeleton } from "@mui/material";
import { get } from "../api";
import { useAuth } from "../auth/AuthContext";
import { SEARCH_BY_OWNER } from "../constants";

import { Map, SearchAutocompleteContainer } from "../components";
import { CountyOwner, AutocompleteSearchOption } from "../types";

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  gap: 12px;
`;

const OwnerSearchTab: React.FC = () => {
  const { logout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [owner, setOwner] = useState<CountyOwner | null>(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth * 0.8,
    height: window.innerHeight * 0.8,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth * 0.8,
        height: window.innerHeight * 0.8,
      });
    };

    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchOwner = async (ownerId: string) => {
    setIsLoading(true);
    const { result, status } = await get(`/owners/${ownerId}`, logout);
    if (result) {
      setOwner(result);
    }
    setIsLoading(false);
  };

  const handleQueryValueSelected = (
    autocompleteOption: AutocompleteSearchOption
  ) => {
    fetchOwner(autocompleteOption.id);
  };

  const renderSearchSubtitleContent = () => {
    return (
      <div>
        {owner && <h3>{owner.name}</h3>}
        {owner && <h4>{owner.properties.length} properties found</h4>}
      </div>
    );
  };

  return (
    <TabPanel value={SEARCH_BY_OWNER}>
      <TabContainer>
        <SearchAutocompleteContainer
          label={"Search by Owner"}
          endpoint={"/owners"}
          onQueryValueSelected={handleQueryValueSelected}
          subtitleContent={renderSearchSubtitleContent()}
          parentLoading={isLoading}
        />
        <div style={{ height: windowSize.height, width: windowSize.width }}>
          {isLoading ? (
            <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
          ) : (
            <Map
              properties={
                owner && owner.properties.length ? owner.properties : []
              }
            />
          )}
        </div>
      </TabContainer>
    </TabPanel>
  );
};

export default OwnerSearchTab;
