import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { CircularProgress } from "@mui/material";

interface AISearchFieldProps {
  onSubmit: (inputValue: string) => void;
  isLoading: boolean;
}

const AISearchField: React.FC<AISearchFieldProps> = ({
  onSubmit,
  isLoading,
}) => {
  const [inputValue, setInputValue] = React.useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(inputValue);
  };

  return (
    <Paper
      component="form"
      onSubmit={handleSubmit}
      sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: 400 }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Type your question here..."
        inputProps={{ "aria-label": "search google maps" }}
        onChange={(e) => setInputValue(e.target.value)}
      />
      {isLoading ? (
        <CircularProgress sx={{ p: "10px" }} />
      ) : (
        <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
          <SearchIcon />
        </IconButton>
      )}
    </Paper>
  );
};

export default AISearchField;
