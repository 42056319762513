import React, { useState } from "react";
import styled from "styled-components";
import { TabPanel } from "@mui/lab";
import { Skeleton } from "@mui/material";
import { get } from "../api";
import { useAuth } from "../auth/AuthContext";
import { SEARCH_BY_COUNTY } from "../constants";

import { CountyDataView, SearchAutocompleteContainer } from "../components";
import { County, AutocompleteSearchOption } from "../types";

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  gap: 12px;
`;

const CountySearchTab: React.FC = () => {
  const { logout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [county, setCounty] = useState<County | null>(null);

  const fetchCounty = async (
    countyId: string,
    page: number,
    pageSize: number
  ) => {
    setIsLoading(true);
    const { result, status } = await get(
      `/counties/${countyId}/owners?page=${page}&pageSize=${pageSize}`,
      logout
    );
    if (result) {
      setCounty(result);
    }
    setIsLoading(false);
  };

  const handleQueryValueSelected = (
    autocompleteOption: AutocompleteSearchOption
  ) => {
    fetchCounty(autocompleteOption.id, 1, 10);
  };

  const handlePageChange = (page: number, pageSize: number) => {
    // + 1 b/c datagrid starts at 0 index but django starts at 1
    fetchCounty(county!.id, page + 1, pageSize);
  };

  const renderSearchSubtitleContent = () => {
    return county
      ? `Please be patient. ${county.name} County has ${county.totalPropertyCount} properties.`
      : null;
  };

  return (
    <TabPanel value={SEARCH_BY_COUNTY}>
      <TabContainer>
        <SearchAutocompleteContainer
          label={"Search by County"}
          endpoint={"/counties"}
          onQueryValueSelected={handleQueryValueSelected}
          subtitleContent={renderSearchSubtitleContent()}
          parentLoading={isLoading}
        />
        {isLoading ? (
          <Skeleton variant="rectangular" width={300} height={500} />
        ) : (
          <CountyDataView
            rows={county ? county.owners : []}
            onPageChange={handlePageChange}
            pagination={
              county
                ? county.pagination
                : {
                    page: 0,
                    pageSize: 10,
                    totalRowCount: 0,
                    hasNext: false,
                    hasPrevious: false,
                  }
            }
          />
        )}
      </TabContainer>
    </TabPanel>
  );
};

export default CountySearchTab;
