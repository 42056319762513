import React from "react";
import styled from "styled-components";
import { Popup, Marker } from "react-map-gl";
import { Feature, Point } from "geojson";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

import { Typography } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Property } from "../types";

interface MapMarkerProps {
  feature: Feature<Point>;
  property: Property;
}

const PopupContent = styled.div`
  padding: 10px;
`;

const MapMarker: React.FC<MapMarkerProps> = ({ property, feature }) => {
  const [showPopup, setShowPopup] = React.useState(false);

  const generateGoogleMapsLink = (property: Property) => {
    let address = "";
    if (property.address.number) {
      address += property.address.number + " ";
    }
    if (property.address.streetPrefix) {
      address += property.address.streetPrefix + " ";
    }
    if (property.address.street) {
      address += property.address.street + " ";
    }
    if (property.address.streetSuffix) {
      address += property.address.streetSuffix + " ";
    }
    if (property.address.city) {
      address += property.address.city + " ";
    }
    if (property.address.state) {
      address += property.address.state + " ";
    }
    if (property.address.zipCode) {
      address += property.address.zipCode;
    }
    return `https://www.google.com/maps/search/?api=1&query=${address}`;
  };

  return (
    <ClickAwayListener onClickAway={() => setShowPopup(false)}>
      <Marker
        latitude={feature.geometry.coordinates[1]}
        longitude={feature.geometry.coordinates[0]}
        anchor="center"
        pitchAlignment="map"
        onClick={() => setShowPopup(true)}
      >
        <LocationOnIcon />
        {showPopup && (
          <Popup
            longitude={property.address.lng}
            latitude={property.address.lat}
            anchor="top"
            closeOnClick={false}
            onClose={() => setShowPopup(false)}
          >
            <PopupContent>
              <Typography variant="subtitle2">
                Owner: {property.owner}
              </Typography>
              <Typography variant="body2">
                Address:{" "}
                <a
                  target="_blank"
                  href={generateGoogleMapsLink(property)}
                  rel="noreferrer"
                >
                  {property.address.number} {property.address.streetPrefix}{" "}
                  {property.address.street} {property.address.streetSuffix},{" "}
                  {property.address.city}, {property.address.state}
                </a>
              </Typography>
              <Typography variant="subtitle2">
                Market value:
                {property.marketValue.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </Typography>
              <Typography variant="subtitle2">
                County Property ID: {property.countyPropertyId}
              </Typography>
            </PopupContent>
          </Popup>
        )}
      </Marker>
    </ClickAwayListener>
  );
};

export default MapMarker;
