import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  TextField,
  Button,
  Container,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import { FullStory } from "@fullstory/browser";
import { useNavigate } from "react-router-dom";
import { post } from "../api";
import { useAuth } from "../auth/AuthContext";
import { SESSION_NAME } from "../constants";

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Login: React.FC = () => {
  const { login, logout } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem(SESSION_NAME)) {
      navigate("/");
    }
  });

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleLogin = async () => {
    setIsLoading(true);
    const { result, status } = await post(
      "/login",
      { email, password },
      logout
    );
    if (status === 201) {
      login(result.sessionKey);
      FullStory("setIdentity", {
        uid: result.userId,
        properties: {
          email: result.email,
        },
      });
      navigate(`/`);
    } else if (status === 401) {
      setError(result.message);
    }
    setIsLoading(false);
  };

  return (
    <Container maxWidth="xs">
      <div>
        <h2>Welcome</h2>
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={handleEmailChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                value={password}
                onChange={handlePasswordChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" color="error">
                {error}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {isLoading && <CircularProgress />}
              {!isLoading && (
                <ButtonContainer>
                  <Button
                    style={{ marginBottom: 16 }}
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handleLogin}
                  >
                    Login
                  </Button>
                  <a href="/reset-password">Forgot password?</a>
                </ButtonContainer>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default Login;
