import React, { useState } from "react";
import styled from "styled-components";
import { useAuth } from "./auth/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import { Login, PasswordReset, Search } from "./pages";
import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Button,
  CssBaseline,
  Toolbar,
  Drawer,
  ListItemText,
  ListItemButton,
  List,
  Typography,
  useTheme,
  IconButton,
  useMediaQuery,
  styled as MuiStyled,
} from "@mui/material";
import {
  Routes,
  Link,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { APP_NAME, WWW } from "./constants";

const AppContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const AppMain = styled.main`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const Banner = styled.div`
  width: 100%;
  background-color: #ff9800;
  color: white;
  text-align: center;
  padding: 10px 0;
`;

const NavLink = MuiStyled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  marginLeft: 16,
  textDecoration: "none",
}));

const App: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:768px)");
  const [openDrawer, setOpenDrawer] = useState(false);
  const location = useLocation();

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const privatize = (children: React.ReactNode) => {
    return <PrivateRoute>{children}</PrivateRoute>;
  };

  const isItemSelected = (path: string) => {
    return location.pathname === path;
  };

  const handleListItemClick = () => {
    if (isMobile) {
      toggleDrawer();
    }
  };

  const renderListItemButton = (route: string, text: string) => {
    return (
      <ListItemButton
        component={Link}
        to={route}
        selected={isItemSelected(route)}
        onClick={handleListItemClick}
      >
        <ListItemText primary={text} />
      </ListItemButton>
    );
  };

  const drawerContent = (isAuthenticated: boolean) => (
    <List>
      {isAuthenticated && (
        <>
          {renderListItemButton("/", "Search")}
          {/* {renderListItemButton("/accounts", "Account")} */}
          <ListItemButton>
            <a
              href="mailto:support@summerlemonade.xyz"
              style={{
                color: theme.palette.primary.main,
                textDecoration: "none",
              }}
            >
              <ListItemText primary="Contact Us" />
            </a>
          </ListItemButton>
        </>
      )}
    </List>
  );

  function NavigationHeader() {
    const location = useLocation();
    const navigate = useNavigate();
    const { isAuthenticated } = useAuth();
    return (
      <AppBar position="static" color="default">
        <Toolbar>
          {isMobile ? (
            <>
              {isAuthenticated && (
                <IconButton onClick={toggleDrawer}>
                  <MenuIcon />
                </IconButton>
              )}
              <Link
                to={WWW}
                target="_blank"
                style={{
                  flex: 1,
                  color: theme.palette.primary.main,
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    color: theme.palette.primary.main,
                  }}
                >
                  <Typography variant="h6">{APP_NAME}</Typography>
                  <Typography variant="body2" style={{ marginLeft: 6 }}>
                    Beta
                  </Typography>
                </div>
              </Link>
              {location.pathname !== "/sign-up" && !isAuthenticated && (
                <Button
                  variant="contained"
                  onClick={() => navigate("/sign-up")}
                >
                  Sign up
                </Button>
              )}
              <Drawer anchor="left" open={openDrawer} onClose={toggleDrawer}>
                {drawerContent(isAuthenticated)}
              </Drawer>
            </>
          ) : (
            <>
              <Link
                to={WWW}
                target="_blank"
                style={{
                  flex: 1,
                  color: theme.palette.primary.main,
                  textDecoration: "none",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography variant="h6">{APP_NAME}</Typography>
                  <Typography variant="body2" style={{ marginLeft: 6 }}>
                    Beta
                  </Typography>
                </div>
              </Link>
              {isAuthenticated ? (
                <LinkContainer>
                  <Link
                    to="/"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textDecoration: "none",
                      marginLeft: "16px",
                      color: theme.palette.secondary.main,
                    }}
                  >
                    Search
                  </Link>
                  {/* <NavLink to="/accounts">Account</NavLink> */}
                  <div
                    style={{
                      marginLeft: "16px",
                    }}
                  >
                    <a
                      style={{
                        textDecoration: "none",
                        color: theme.palette.secondary.main,
                      }}
                      href="mailto:support@summerlemonade.xyz"
                    >
                      Contact Us
                    </a>
                  </div>
                </LinkContainer>
              ) : location.pathname !== "/sign-up" ? (
                <Button
                  variant="contained"
                  onClick={() => navigate("/sign-up")}
                >
                  Sign up
                </Button>
              ) : null}
            </>
          )}
        </Toolbar>
      </AppBar>
    );
  }

  return (
    <AppContainer>
      <Banner>
        Vizero AI is in beta and some data may be inaccurate or out-of-date.
      </Banner>
      <CssBaseline />
      <NavigationHeader />
      <AppMain>
        <Routes>
          <Route path="/" element={privatize(<Search />)} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/reset-password"
            element={<PasswordReset isSignUp={false} />}
          />
          <Route path="/sign-up" element={<PasswordReset isSignUp={true} />} />
        </Routes>
      </AppMain>
    </AppContainer>
  );
};

export default App;
