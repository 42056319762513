import React, { useState } from "react";
import { CircularProgress, TextField, Autocomplete } from "@mui/material";
import { AutocompleteSearchOption } from "../types";

interface SearchAutocompleteProps {
  onQueryValueChanged: (q: string) => void;
  onQueryValueSelected: (autocompleteOption: AutocompleteSearchOption) => void;
  isLoading: boolean;
  options: AutocompleteSearchOption[];
  label: string;
}

const SearchAutocomplete: React.FC<SearchAutocompleteProps> = ({
  onQueryValueChanged,
  onQueryValueSelected,
  isLoading,
  options,
  label,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Autocomplete
      id="asynchronous-demo"
      sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.label}
      options={options}
      loading={isLoading}
      onInputChange={(event, value) => {
        onQueryValueChanged(value);
      }}
      onChange={(event, value) => {
        if (value) {
          onQueryValueSelected(value);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default SearchAutocomplete;
