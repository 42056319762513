import { API_HOST, SESSION_NAME } from "./constants";

const headers: Record<string, string> = { Accept: "application/json" };

export const get = async <T extends Record<string, string>>(
  endpoint: string,
  logout: (isHardLogout: boolean) => void,
  queryParams?: T | null
): Promise<any> => {
  const queryStringParts: string[] = [];

  if (queryParams !== null && typeof queryParams === "object") {
    // Loop through the keys in queryParams
    Object.keys(queryParams).forEach((key) => {
      // Get the value corresponding to the key
      const value = queryParams[key];

      // Encode both the key and value and add them to the array
      queryStringParts.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    });
  }

  const queryString = queryStringParts.join("&");

  const url = endpoint + (queryString ? `?${queryString}` : "");

  try {
    if (localStorage.getItem(SESSION_NAME)) {
      headers[SESSION_NAME] = localStorage.getItem(SESSION_NAME) as string;
    }
    const response = await fetch(`${API_HOST}${url}`, {
      headers: headers,
      credentials: "include",
    });

    return processResponse(response, logout);
  } catch (error) {
    // Handle error
    console.error("GET request error:", error);
    throw error;
  }
};

export const post = async (
  endpoint: string,
  data: any | null,
  logout: (isHardLogout: boolean) => void
): Promise<any> => {
  try {
    headers["Content-Type"] = "application/json";
    if (localStorage.getItem(SESSION_NAME)) {
      headers[SESSION_NAME] = localStorage.getItem(SESSION_NAME) as string;
    }
    const response = await fetch(`${API_HOST}${endpoint}`, {
      method: "POST",
      headers: headers,
      credentials: "include", // Include credentials (session cookie)
      body: JSON.stringify(data),
    });
    return processResponse(response, logout);
  } catch (error) {
    // Handle error
    console.error("POST request error:", error);
    throw error;
  }
};

export const put = async (
  endpoint: string,
  id: string,
  data: any | null,
  logout: (isHardLogout: boolean) => void
): Promise<any> => {
  try {
    headers["Content-Type"] = "application/json";
    if (localStorage.getItem(SESSION_NAME)) {
      headers[SESSION_NAME] = localStorage.getItem(SESSION_NAME) as string;
    }
    const response = await fetch(`${API_HOST}${endpoint}/${id}`, {
      method: "PUT",
      headers: headers,
      credentials: "include", // Include credentials (session cookie)
      body: JSON.stringify(data),
    });
    return processResponse(response, logout);
  } catch (error) {
    // Handle error
    console.error("PUT request error:", error);
    throw error;
  }
};

export const deleteReq = async (
  endpoint: string,
  logout: (isHardLogout: boolean) => void
): Promise<any> => {
  try {
    headers["Content-Type"] = "application/json";
    if (localStorage.getItem(SESSION_NAME)) {
      headers[SESSION_NAME] = localStorage.getItem(SESSION_NAME) as string;
    }
    const response = await fetch(`${API_HOST}${endpoint}`, {
      method: "DELETE",
      headers: headers,
      credentials: "include", // Include credentials (session cookie)
    });
    return processResponse(response, logout);
  } catch (error) {
    // Handle error
    console.error("POST request error:", error);
    throw error;
  }
};

export const postFormData = async (
  endpoint: string,
  data: FormData,
  logout: (isHardLogout: boolean) => void
): Promise<any> => {
  try {
    delete headers.Accept;
    if (localStorage.getItem(SESSION_NAME)) {
      headers[SESSION_NAME] = localStorage.getItem(SESSION_NAME) as string;
    }
    const response = await fetch(`${API_HOST}${endpoint}`, {
      method: "POST",
      headers: headers,
      credentials: "include", // Include credentials (session cookie)
      body: data,
    });

    return processResponse(response, logout);
  } catch (error) {
    // Handle error
    console.error("POST FormData request error:", error);
    throw error;
  }
};

const processResponse = async (
  response: Response,
  logout: (isHardLogout: boolean) => void
) => {
  if (response.status === 500) {
    alert("Something went wrong.");
    return { result: null, status: response.status };
  } else if (response.status === 504) {
    alert(
      "Looks like the server is working overtime! Try again in a few seconds."
    );
    return { result: null, status: response.status };
  } else if (response.status === 503) {
    const result = await response.json();
    alert(result.message);
    return { result: null, status: response.status };
  }

  if (response.status === 401) {
    logout(true);
  } else if (response.status === 403) {
    logout(false);
  }

  const result = await response.json();
  if (response.status === 400) {
    if (result.message) {
      alert(result.message);
    }
    return { result: null, status: response.status };
  }
  return { result, status: response.status };
};
