import React, { useState } from "react";
import styled from "styled-components";
import { TabList, TabContext } from "@mui/lab";
import { Tab } from "@mui/material";
import BoltIcon from "@mui/icons-material/Bolt";
import { SEARCH_BY_OWNER, AI_SEARCH, SEARCH_BY_COUNTY } from "../constants";

import { AISearchTab, CountySearchTab, OwnerSearchTab } from "../tabs";

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

const Search: React.FC = () => {
  const [tabValue, setTabValue] = useState("searchByOwner");

  const handleTabValueChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setTabValue(newValue);
  };

  return (
    <SearchContainer>
      <TabContext value={tabValue}>
        <TabList onChange={handleTabValueChange}>
          <Tab label="Search by Owner" value={SEARCH_BY_OWNER} />
          <Tab
            label="Search with AI"
            value={AI_SEARCH}
            icon={<BoltIcon />}
            iconPosition="end"
          />
          {/* <Tab label="Search by County" value={SEARCH_BY_COUNTY} /> */}
        </TabList>
        <OwnerSearchTab />
        {/* <CountySearchTab /> */}
        <AISearchTab />
      </TabContext>
    </SearchContainer>
  );
};

export default Search;
