import React, { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SESSION_NAME } from "../constants";

interface AuthContextType {
  isAuthenticated: boolean;
  login: (sessionKey: string) => void;
  logout: (isHardLogout: boolean) => void;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate();
  const isAuthenticated = () => {
    return localStorage.getItem(SESSION_NAME) ? true : false;
  };

  const login = (sessionKey: string) => {
    localStorage.setItem(SESSION_NAME, sessionKey);
    localStorage.setItem("showHelp", "true");
  };

  const logout = (isHardLogout: boolean) => {
    if (isHardLogout) {
      localStorage.removeItem(SESSION_NAME);
      navigate("/login");
    } else {
      navigate("/account");
    }
  };

  return (
    <AuthContext.Provider
      value={{ isAuthenticated: isAuthenticated(), login, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};
