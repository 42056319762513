const PROD = true;

export const API_HOST = PROD
  ? "https://vizero-9cb3389fdeaa.herokuapp.com/api"
  : "http://127.0.0.1:8000/api";
export const STRIPE_KEY = PROD
  ? "pk_live_51NVJwwIbBsHz9LNRl4Cx6BUzNGYUY8pJjcFVhGDIQrGNVd19WTCmAmRy0TEgAY1COkxH5lXkc6m2Rq2Iw2npUhjT00k20A7wYT"
  : "pk_test_51NVJwwIbBsHz9LNRH541cjDsbtFPFg2Ro6iVr2msL367qRsx9jvh9e7AQO77fbXg7y50YbUzbvM7cRNkp6rgVb8h00mhxhJ0Cf";

export const MAPBOX_TOKEN =
  "pk.eyJ1IjoiY29ubm9yNTY3ODkiLCJhIjoiY2x1ZndyNG1uMmEzYzJtbjR1N2V1ZzFkaSJ9.95zQrymmjAGPJt1coTaYuw";

export const APP_NAME = "Vizero AI";
export const SESSION_NAME = "losession";

export const SEARCH_BY_OWNER = "searchByOwner";
export const SEARCH_BY_COUNTY = "searchByCounty";
export const AI_SEARCH = "aiSearch";

export const WWW = "https://www.vizeroai.com";
