import React, { useEffect, useState } from "react";
import Map from "react-map-gl";
import { Feature, FeatureCollection, Point } from "geojson";
import "mapbox-gl/dist/mapbox-gl.css";
import { MAPBOX_TOKEN } from "../constants";
import MapMarker from "./MapMarker";
import { Property } from "../types";

interface MapComponentProps {
  properties: Property[];
}

const MapComponent: React.FC<MapComponentProps> = ({ properties }) => {
  const [geoJson, setGeoJson] = useState<FeatureCollection<Point>>({
    type: "FeatureCollection",
    features: [],
  });

  useEffect(() => {
    setGeoJson(propertiesToGeoJson(properties));
  }, [properties]);

  const propertiesToGeoJson = (
    properties: Property[]
  ): FeatureCollection<Point> => {
    const features = properties.map((property) => {
      const feature: Feature<Point> = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [property.address.lng, property.address.lat],
        },
        properties: {
          title: property.owner,
        },
      };
      return feature;
    });

    return {
      type: "FeatureCollection",
      features,
    };
  };

  return (
    <Map
      mapboxAccessToken={MAPBOX_TOKEN}
      initialViewState={{
        longitude: -97.1,
        latitude: 31.5,
        zoom: 10,
      }}
      style={{ height: "100%", width: "100%" }}
      // mapStyle="mapbox://styles/mapbox/streets-v9"
      mapStyle="mapbox://styles/connor56789/clxs3edrz03i701qm17msbskf"
    >
      {geoJson.features.map((feature, index) => (
        <MapMarker key={index} feature={feature} property={properties[index]} />
      ))}
    </Map>
  );
};

export default MapComponent;
