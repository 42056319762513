import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { debounce } from "lodash";
import { get } from "../api";
import { useAuth } from "../auth/AuthContext";

import SearchAutocomplete from "./SearchAutocomplete";
import { AutocompleteSearchOption } from "../types";

interface SearchAutocompleteContainerProps {
  label: string;
  endpoint: string;
  subtitleContent: React.ReactNode;
  onQueryValueSelected: (autocompleteOption: AutocompleteSearchOption) => void;
  parentLoading: boolean;
}

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

const SearchAutocompleteContainer: React.FC<
  SearchAutocompleteContainerProps
> = ({
  label,
  endpoint,
  subtitleContent,
  onQueryValueSelected,
  parentLoading,
}) => {
  const { logout } = useAuth();
  const [options, setOptions] = useState<AutocompleteSearchOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const fetchAutocompleteData = async (q: string) => {
    const endpointWithParams = `${endpoint}?q=${q}`;
    setIsLoading(true);
    const { result, status } = await get(endpointWithParams, logout);
    if (result) {
      setOptions(result);
    }
    setIsLoading(false);
  };

  const debouncedFetchAutocompleteData = useMemo(
    () => debounce(fetchAutocompleteData, 500),
    [endpoint]
  );
  useEffect(() => {
    if (searchQuery) {
      debouncedFetchAutocompleteData(searchQuery);
    } else {
      setOptions([]);
    }

    // Cleanup function to cancel the debounced call if component unmounts or if searchQuery changes
    return () => {
      debouncedFetchAutocompleteData.cancel();
    };
  }, [searchQuery, debouncedFetchAutocompleteData]);

  const handleQueryValueSelected = (
    autocompleteOption: AutocompleteSearchOption
  ) => {
    onQueryValueSelected(autocompleteOption);
  };

  const handleQueryValuedChanged = (q: string) => {
    setSearchQuery(q);
  };

  return (
    <SearchContainer>
      <SearchAutocomplete
        label={label}
        options={options}
        onQueryValueChanged={handleQueryValuedChanged}
        onQueryValueSelected={handleQueryValueSelected}
        isLoading={isLoading || parentLoading}
      />
      {subtitleContent}
    </SearchContainer>
  );
};

export default SearchAutocompleteContainer;
