import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridColDef,
  GridRowsProp,
  GridValidRowModel,
} from "@mui/x-data-grid";
import { LabelValueArray } from "../types";

interface TwoColumnDataGridProps {
  data: LabelValueArray[];
}

interface DataGridRow extends GridValidRowModel {
  id: number;
  [key: string]: string | number | undefined;
}

const AISearchResultDataGrid: React.FC<TwoColumnDataGridProps> = ({ data }) => {
  const [width, setWidth] = useState<number>(window.innerWidth * 0.8);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth * 0.8);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!data.length || !data[0].length) {
    return null;
  }

  // Extract column labels and dynamically create column definitions
  const columnLabels = data[0].map((item) => item.label);
  const columns: GridColDef<DataGridRow>[] = columnLabels.map(
    (label, index) => ({
      field: `field${index}`,
      headerName: label,
      width: 200,
    })
  );

  // Transform the data into a format suitable for the DataGrid
  const rows: GridRowsProp<DataGridRow> = data.map((row, index) => {
    const rowData: DataGridRow = {
      id: index,
    };

    row.forEach((item, i) => {
      if (item.valueType === "currency") {
        rowData[`field${i}`] = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(item.value);
      } else if (item.valueType === "number") {
        rowData[`field${i}`] = new Intl.NumberFormat("en-US").format(
          item.value
        );
      } else {
        rowData[`field${i}`] = item.value;
      }
    });

    return rowData;
  });

  return (
    <Box sx={{ height: 400, width: width }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10, 25, 100]}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

export default AISearchResultDataGrid;
