import React, { useState } from "react";
import styled from "styled-components";
import { TabPanel } from "@mui/lab";
import { Typography, Button, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import BlockIcon from "@mui/icons-material/Block";
import { get, post } from "../api";
import { useAuth } from "../auth/AuthContext";
import { AI_SEARCH } from "../constants";

import {
  AISearchField,
  AISearchResultDataGrid,
  SearchAutocompleteContainer,
} from "../components";
import { AISearchResult, AutocompleteSearchOption } from "../types";

const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  gap: 24px;
`;

const FeedbackContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const RatingContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
`;

const RatingButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: row;
`;

const CountySearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

const EditCountyRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

const AISearchTab: React.FC = () => {
  const { logout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [searchResult, setSearchResult] = useState<AISearchResult | null>(null);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [selectedCounty, setCountySelected] =
    useState<AutocompleteSearchOption | null>(null);

  const fetchSearchResults = async (query: string) => {
    setFeedbackSubmitted(false);
    setIsLoading(true);
    const { result, status } = await get(
      `/ai-search?q=${query}&countyId=${selectedCounty?.id}`,
      logout
    );
    if (result) {
      setSearchResult(result);
    }
    setIsLoading(false);
  };

  const submitFeedback = async (feedback: number) => {
    const { result, status } = await post(
      `/ai-search/feedback/${searchResult?.feedbackId}`,
      { answeredUserQuery: feedback },
      logout
    );
    if (result) {
      setFeedbackSubmitted(true);
    }
  };

  const renderCountySearch = () => {
    return (
      <CountySearchContainer>
        {selectedCounty ? (
          <EditCountyRowContainer>
            <TextField disabled label={selectedCounty.label} />
            <Button onClick={() => setCountySelected(null)}>Edit</Button>
          </EditCountyRowContainer>
        ) : (
          <SearchAutocompleteContainer
            label={"Which county are you interested in?"}
            endpoint={"/counties"}
            onQueryValueSelected={handleCountySelected}
            subtitleContent={null}
            parentLoading={isLoading}
          />
        )}
      </CountySearchContainer>
    );
  };

  const renderFeedbackContainer = () => {
    return (
      <FeedbackContainer>
        {!isLoading && (
          <RatingContainer>
            <Typography variant="h6">
              {feedbackSubmitted
                ? "Thank you."
                : "Did these results answer your question?"}
            </Typography>
            {!feedbackSubmitted && (
              <RatingButtonContainer>
                <Button
                  variant="contained"
                  color="error"
                  endIcon={<BlockIcon />}
                  onClick={() => submitFeedback(0)}
                >
                  No
                </Button>
                <Button
                  variant="contained"
                  color="success"
                  endIcon={<CheckIcon />}
                  onClick={() => submitFeedback(1)}
                >
                  Yes
                </Button>
              </RatingButtonContainer>
            )}
          </RatingContainer>
        )}
      </FeedbackContainer>
    );
  };

  const handleCountySelected = (
    autocompleteOption: AutocompleteSearchOption
  ) => {
    setCountySelected(autocompleteOption);
  };

  return (
    <TabPanel value={AI_SEARCH}>
      <TabContainer>
        {renderCountySearch()}
        {selectedCounty && (
          <AISearchField
            isLoading={isLoading}
            onSubmit={(val: string) => fetchSearchResults(val)}
          />
        )}
        {searchResult && renderFeedbackContainer()}
        {searchResult && <AISearchResultDataGrid data={searchResult.results} />}
      </TabContainer>
    </TabPanel>
  );
};

export default AISearchTab;
